import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ButtonWIcon from '../Buttons/ButtonWIcon';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RatingChip from '../Chips/RatingChip';
import Skeleton from '@mui/material/Skeleton';
import { addItem } from '../../redux/shopCartRedux';
import useImageValidation from '../../utils/ImageValidator';
import { Button } from '@mui/material';

type ProductBoxProps = {
  id:string
  productName: string | null;
  price: number | null;
  imgUrl: string;
  shortDesc: string | null;
  rating: number | null;
};

const ProductBox: React.FC<ProductBoxProps> = ({id, productName, price, imgUrl, shortDesc, rating }) => {
  const { imgUrlIsValid, isLoading } = useImageValidation(imgUrl);

  const formattedPrice = price?.toLocaleString('en', { minimumFractionDigits: 2 });

  const handleWhatsAppClick = () => {
    const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER;;
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank');
  };

  const dispatch = useDispatch();

  const handleAddToCart = () => {
    if (productName && price) {
      dispatch(addItem({
        id: id, // Use a unique ID if available
        name: productName,
        price,
        quantity: 1,
        imgUrl
      }));
    }
  };

  return (
    <Card sx={{ width: 260, borderRadius: '0.5rem' }}>
      {isLoading ? (
        <Skeleton variant="rectangular" width={280} height={200} animation="wave" />
      ) : imgUrlIsValid ? (
        <Link to={`/product/${id}`}>
          <CardMedia
            sx={{ width: 260, height: 200, padding: '1em 1em 0 1em', backgroundSize: 'contain' }}
            image={imgUrl ? imgUrl:'https://images.samsung.com/mx/smartphones/galaxy-s24-ultra/buy/product_color_gray.png?imwidth=480'}
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              const target = e.target as HTMLImageElement;
              target.src =
                'https://images.samsung.com/mx/smartphones/galaxy-s24-ultra/buy/product_color_gray.png?imwidth=480';
            }}
            title="Product Image"
          />
         </Link>
      ) : (
        <Link to={`/product/${id}`}>
          <div className="flex justify-center items-center">
            <img
              src={require('../../assets/images/icons/telefono-inteligente.png')}
              alt="Phone Icon"
              style={{ width: 'auto%', height: '100%', maxHeight: 200, padding: '1em 1em 0 1em' }}
            />
          </div>
        </Link>
      )}
      <CardContent className="flex justify-between p-2 mt-5">
        {isLoading ? (
          <Skeleton variant="text" width={100} animation="wave" />
        ) : (
          <RatingChip rating={rating} className="" />
        )}
        {isLoading ? (
          <Skeleton variant="text" width={80} animation="wave" />
        ) : (
          <Typography variant="h6">${formattedPrice}</Typography>
        )}
      </CardContent>
      <CardContent>
        {isLoading ? (
          <>
            <Skeleton variant="text" width={200} animation="wave" />
            <Skeleton variant="text" width={150} animation="wave" />
          </>
        ) : (
          <>
            <Typography gutterBottom variant="body1" component="div">
              {productName}
            </Typography>
            <Typography variant="body2" color="text.secondary" className="h-10">
              {shortDesc == 'No description' ? null:shortDesc}
            </Typography>
          </>
        )}
      </CardContent>
      <CardActions className="flex flex-wrap gap-0 !p-0">
      <Button color='primary' variant="contained" className='w-full' onClick={handleWhatsAppClick}>Contratar</Button>
      </CardActions>
    </Card>
  );
};

export default ProductBox;
