import { SmartphoneDetailsInterface, SmartphoneInterface, SmartphonePriceInterface} from "../../../../constant/PhoneModel";

export const expectedHeaders = [
    "MARCA", "MODALIDAD", "EQUIPO", "SAP", "COLORES", "CAPACIDAD", 
    "RAM", "CAMARA", "GENERACION", "PANTALLA", "ANDROID", 
    "INF. ADICIONAL", "IMAGEN", "PRECIO"
];

export const validateHeaders = (headers: string[]): boolean => {
    // Validate the number of headers
    if (headers.length !== expectedHeaders.length) {
      return false;
    }
  
    // Validate the content and order of headers
    return headers.every((header, index) => header.trim() === expectedHeaders[index]);
};

// Transform row data into smartphone objects
export const transformDataToSmartphones = (row: any): any[] => {
    if (row.length < 6) {
      console.error('Row does not contain enough data:', row);
      return [];
    }
  
    const sapIdsString = row[3] !== undefined ? row[3].toString().trim() : 'Desconocido SAP';
    const sapIds = sapIdsString.includes('/') 
      ? sapIdsString.split('/').map((id: string) => id.trim() || 'Desconocido SAP') 
      : [sapIdsString || 'Desconocido SAP'];
      
    const colors = (row[4] || 'Desconocido Color')
      .split(/\s*,\s*|\s+Y\s+/)
      .map((color: string) => color.trim() || 'Desconocido Color');
  
    return sapIds.map((sapId: string, index: number) => {
      const color = colors[index] || 'Desconocido Color';
      return {
        id: Number(sapId),
        brand: row[0],
        modalidad: row[1],
        productName: row[2],
        smartphoneId: sapId,
        price: Number(row[13]) || 0,
        capacity: parseInt(String(row[5] || '').replace(/\s*GB/i, '').replace(/\D/g, '').trim()) || 0,
        screenSize: Number(String(row[6] || '').replace(/["”]/g, '').trim()) || 0,
        opSystem: row[10] || ' ',
        camera: row[7] || ' ',
        color,
        connectivity: row[8],
        memoryRam: row[6] || ' ',
        features: {},
        description: ' ',
        productImages: [], // Replace with actual logic for images
        rating: null, // Replace with default rating logic
      };
    });
  };
  
  // Map data to interfaces
export const mapDataToInterfaces = (dataArray: any[]): {
smartphonePrices: SmartphonePriceInterface[],
smartphones: SmartphoneInterface[],
smartphoneDetails: SmartphoneDetailsInterface[]
} => {
const smartphonePrices: SmartphonePriceInterface[] = [];
const smartphones: SmartphoneInterface[] = [];
const smartphoneDetails: SmartphoneDetailsInterface[] = [];
 
dataArray.forEach((item) => {
    const defaultImgUrl = item.productImages[0] || 'https://storage.googleapis.com/4-evolucion-cloud-storage/Smartphones/telefono-inteligente.png';
    if (item.smartphoneId === 'Desconocido SAP') return;

    smartphonePrices.push({
    id: 0,
    capacity: item.capacity,
    color: item.color,
    contractMode: item.modalidad,
    price: item.price,
    smartphoneId: item.smartphoneId,
    });

    smartphones.push({
    id: item.smartphoneId,
    brand: item.brand,
    productName: item.productName,
    shortDesc: 'No description',
    imgUrl: defaultImgUrl,
    rating: item.rating ? item.rating:3,
    inStock: true,
    price: item.price,
    capacity: item.capacity,
    });

    smartphoneDetails.push({
    id: item.id || 0,
    smartphoneId: item.smartphoneId,
    price: item.price,
    capacity: item.capacity,
    screenSize: item.screenSize,
    opSystem: item.opSystem.toString(),
    camera: item.camera,
    color: item.color,
    connectivity: item.connectivity,
    memoryRam: item.memoryRam,
    features: item.features || {},
    description: item.description,
    productImages: item.productImages,
    rating: item.rating,
    });
});

return { smartphonePrices, smartphones, smartphoneDetails };
};