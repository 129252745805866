import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Stack,
  CircularProgress,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {Edit, Save, Cancel, Close } from '@mui/icons-material';
import { CompanyUserInterface } from '../../../../constant/CompanyInterface';

interface CompanyUserEditModalProps {
  open: boolean;
  onClose: () => void;
  user: CompanyUserInterface;
  onSave: (updatedUser: CompanyUserInterface) => Promise<void>;
}
const CompanyUserEditModal: React.FC<CompanyUserEditModalProps> = ({
  open,
  onClose,
  user,
  onSave,
}) => {
  const [formData, setFormData] = useState<CompanyUserInterface>(user);
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [feedback, setFeedback] = useState<string | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const validateForm = () => {
    let newErrors: Record<string, string> = {};
    if (!formData.name) newErrors.name = 'El nombre es obligatorio';
    if (!formData.lastName) newErrors.lastName = 'El apellido es obligatorio';
    if (!formData.email) newErrors.email = 'El correo electrónico es obligatorio';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Formato de correo inválido';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setConfirmDialogOpen(false);
    setLoading(true);
    setFeedback(null);
    try {
      await onSave(formData);
      setFeedback('Usuario actualizado exitosamente');
      setTimeout(() => {
        setFeedback(null);
        setIsEditing(false); // Volver al modo vista después de guardar con éxito
      }, 1500);
    } catch (error) {
      setFeedback('Error al guardar el usuario. Intente nuevamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenConfirmDialog = () => {
    if (validateForm()) {
      setConfirmDialogOpen(true);
    }
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };



  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ p: 4, width: '100%', maxWidth: 450, bgcolor: 'background.paper', margin: '100px auto', borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom>
          Detalles del Usuario
        </Typography>

        {isEditing ? (
          // Modo de Edición
          <>
            <Stack spacing={2}>
              <TextField
                label="Nombre"
                name="name"
                value={formData.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
                fullWidth
              />
              <TextField
                label="Apellido"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                error={!!errors.lastName}
                helperText={errors.lastName}
                fullWidth
              />
              <TextField
                label="Correo Electrónico"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                fullWidth
              />
            </Stack>

            {feedback && (
              <Alert severity={feedback.includes('Error') ? 'error' : 'success'} sx={{ mt: 2 }}>
                {feedback}
              </Alert>
            )}

            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={loading ? <CircularProgress size={24} /> : <Save />}
                onClick={handleOpenConfirmDialog}
                disabled={loading}
              >
                Guardar
              </Button>
              <Button variant="outlined" color="error" startIcon={<Cancel />} onClick={() => setIsEditing(false)}>
                Cancelar
              </Button>
            </Stack>
          </>
        ) : (
          // Modo de Vista
          <>
            <Stack spacing={2} className="flex flex-wrap gap-5 p-4 bg-gray-100 rounded-lg shadow-md">
              <Typography variant="body1">
                <strong>Nombre:</strong> {user.name}
              </Typography>
              <Typography variant="body1">
                <strong>Apellido:</strong> {user.lastName}
              </Typography>
              <Typography variant="body1">
                <strong>Correo Electrónico:</strong> {user.email}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <Button variant="contained" color="primary" startIcon={<Edit />} onClick={() => setIsEditing(true)}>
                Editar
              </Button>
              <Button variant="outlined" color="error" startIcon={<Close />} onClick={onClose}>
                Cerrar
              </Button>
            </Stack>
          </>
        )}

        {/* Confirm Dialog */}
        <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
          <DialogTitle>Confirmar Guardado</DialogTitle>
          <DialogContent>
            <DialogContentText>¿Estás seguro de que quieres guardar los cambios realizados en el usuario?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmDialog} color="error">
              Cancelar
            </Button>
            <Button onClick={handleSubmit} color="primary" autoFocus>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Modal>
  );
};

export default CompanyUserEditModal;