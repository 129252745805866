import React, {useEffect,useState} from 'react'
import ServicesSlider from '../components/Sliders/ServicesSlider'
import {Button, Box, Container, Typography } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { PlanServiceInterface, PlanType, ServiceCardProps } from '../constant/ServicesInterfaces'
import { Link } from 'react-router-dom'
import { getAllPlanServicesByType } from '../services/plansService';
import ServiceCardSlider from '../components/Sliders/ServiceCardSlider';

interface SpecificationProps {
  serviceName: string;
  features: string[];
  description: string;
}
  

const SerivicesPage = () => {
  const[isLoading,setIsLoading] = useState(false);
  const[planServiceList,setPlanServiceList] = useState<PlanServiceInterface[]>([]);
  const[planType,setPlanType]=useState<PlanType>(PlanType.Business)

  useEffect(()=>{
    fetchPlanServicesByType();  
  },[planType])

  const fetchPlanServicesByType= async()=>{
    try {
      // Toggle the isActive status
      const response = await getAllPlanServicesByType(planType);
      // Check if the response is valid
      if (response !== null && typeof response === 'object') {
        setPlanServiceList(response);
        console.log(response);
      } else {
        console.error('Received invalid response:', response);
      }
    } catch (error) {
      console.error('Error updating plan service status:', error);
    }

  }

  const handleToogleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: PlanType,
  ) => {

    setPlanType(newAlignment);

  };
  


  return (
    <>
        <ServicesSlider/>
        <Container maxWidth='xl'>
            <Box className='my-5'>
                <Typography variant='h2' component='h2' sx={{fontWeight:'bold'}}>
                    Encuentra el Plan ideal
                </Typography>
                <Typography variant='h5' component='h5'>
                    En nuestra empresa, la excelencia en la comunicación es nuestra máxima prioridad.
                </Typography>
            </Box>
            <Box className='flex justify-center items-center p-5'>
              <ToggleButtonGroup
                color="primary"
                value={planType}
                exclusive
                onChange={handleToogleChange}
                aria-label="Platform"
              >
                <ToggleButton value={PlanType.Business}>{PlanType.Business}</ToggleButton>
                <ToggleButton value={PlanType.Personal}>{PlanType.Personal}</ToggleButton>
            </ToggleButtonGroup>
            </Box>
            <ServiceCardSlider planServices={planServiceList}/>
            <Box className='text-center'>
            <Typography variant="body2" component="h5" color="textSecondary" gutterBottom>
              *Los precios estan sujetos a cambios sin previo aviso.
            </Typography>
            </Box>
            <Box className='flex justify-center items-centre p-5'>
              <Link to={'/services-build'}>
                <Button variant='contained' className='w-fit py-10 px-5 h-16 w-72 text-2xl' sx={{borderRadius:'26px', fontWeight:'bold'}} color="primary">Encuentra tu Plan Ideal</Button>
              </Link>
            </Box>
        </Container>
    </>
  )
}

export default SerivicesPage;