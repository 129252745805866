import React, { useState } from 'react';
import { Modal, Box, Button, Typography, IconButton } from '@mui/material';
import ExcelReader from '../../Excel/ExcelReader'; // Adjust the import path as necessary
import CloseIcon from '@mui/icons-material/Close'; // Import Close icon
import { SmartphoneInterface } from '../../../../constant/PhoneModel';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxHeight: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  overflow: 'auto',
  p: 4,
  display: 'flex',
  flexDirection: 'column',
};

interface ExcelUploadModalProps {
  existingPhones: SmartphoneInterface[];
}

const ExcelUploadModal: React.FC<ExcelUploadModalProps> = ({ existingPhones }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [updateExistingPhone, setUpdateExistingPhones] = useState<SmartphoneInterface[]>(existingPhones);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  return (
    <>
      <Button onClick={handleOpen} variant="contained" color="primary">
        Agregar Excel
      </Button>

      <Modal open={modalOpen} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component="h2" mb={2}>
              Cargar Archivo Excel
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{
                color: 'white', // Icon color
                backgroundColor: 'red', // Red background
                borderRadius: '50%', // Circular background
                '&:hover': {
                  backgroundColor: 'darkred', // Darker red on hover
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box className="py-10">
            <ExcelReader existingPhones={updateExistingPhone} />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ExcelUploadModal;