// src/services/promotionService.ts
import { SmartphonePriceInterface,UpdatePriceModel } from '../constant/PhoneModel';
import { publicRequest, userRequestByParams } from '../requestMethods/requestMethods';



export const getPriceById = async (id: number) => {
  try {
    const response = await publicRequest.get(`/prices/${id}`);
    return response.data;
  } catch (error: any) {
    // Check if the error is a 404 (Not Found)
    if (error.response && error.response.status === 404) {
      console.warn(`Price with ID ${id} not found.`);
      return null; // Return null when the price is not found
    }
    // Log any other errors and rethrow them
    console.error(`Error fetching price with ID ${id}:`, error);
    throw error;
  }
};



export const createSmarthphonePrice = async (phonePrice:SmartphonePriceInterface) => {
    try {
    const response = await publicRequest.post('/prices/',phonePrice);
    return response.data;
    } catch (error) {
    console.error('Error fetching smartphones:', error);
    throw error;
    }
};

export const createMultipleSmartphonePrices = async (uniqueData: SmartphonePriceInterface[]) => {
    try {
      // Ensure uniqueData is an array before making the API request
      if (!Array.isArray(uniqueData)) {
        throw new Error('Input data must be an array of SmartphonePriceInterface objects');
      }

      console.log(uniqueData);
  
      const response = await publicRequest.post('/prices/create-multiple', uniqueData);
  
      //Check if response data is valid
      if (!response || !response.data) {
        throw new Error('No data received from the server');
      }
  
      return response.data;
    } catch (error) {
      console.error('Error creating multiple smartphone prices:', error);
      throw error; // Optionally re-throw the error if you want to handle it further up the call stack
    }
  };


export const updateSmarthphonePrice = async (id:number,phonePrice:SmartphonePriceInterface) => {
  try {
   const response = await publicRequest.put(`/prices/${id}`,phonePrice);
    return response.data;
  } catch (error) {
    console.error('Error fetching smartphones:', error);
  throw error;
  }
};


export const updateMultiplePricesBySmarthphoneId = async (phonePrices:UpdatePriceModel[]) => {
  try {
  const response = await publicRequest.put(`/prices/updateMuliplePrices`,phonePrices);
  return response.data;
  } catch (error) {
  console.error('Error fetching smartphones:', error);
  throw error;
  }
};


export const deletePriceBySmartphoneId = async (phoneId:string) => {
    try {
    const response = await publicRequest.post(`/prices/smartphoneId/${phoneId}`);
    return response.data;
    } catch (error) {
    console.error('Error fetching smartphones:', error);
    throw error;
    }
};

// Delete a LatestDeatailsroducts
export const deleteLatestPrice= async () => {
  try {
    const response = await publicRequest.delete(`/prices/latest`);
    return response.data;
  } catch (error) {
    console.error('Error deleting promotion:', error);
    throw error;
  }
};