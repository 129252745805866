import React, { useState } from 'react';
import { Modal, Box, Button, TextField, FormControlLabel, Card, CardMedia, IconButton, Switch } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import EditIcon from '@mui/icons-material/Edit';
import { uploadImage } from '../../../../services/gcpService';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

// Interface for Promotion
interface Promotion {
  imgUrl: string;
  title: string;
  description: string;
  shortDesc?: string;
  startDay: string;
  endDay: string;
  isActive: boolean;
  isExclusive: boolean;
}

// Modal style
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const CreatePromotionModal: React.FC<{ open: boolean; onClose: () => void; onSave: (promotion: Promotion) => void; }> = ({ open, onClose, onSave }) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [loadingImage, setLoadingImage] = useState<boolean>(false); // State to manage loading state

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      shortDesc: '',
      startDay: '',
      endDay: '',
      isActive: false,
      isExclusive: false,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      description: Yup.string().required('Description is required'),
      startDay: Yup.string().required('Start Day is required'),
      endDay: Yup.string().required('End Day is required'),
    }),
    onSubmit: async (values) => {
      if (imageFile) {
        setLoadingImage(true); // Start loading image
        try {
          const url = await uploadImage(imageFile, 'Promotions');
          console.log(url);
          onSave({ ...values, imgUrl: url, isActive: values.isActive, isExclusive: values.isExclusive });
          onClose();
        } catch (error) {
          console.error('Failed to upload image:', error);
        } finally {
          setLoadingImage(false); // End loading image
        }
      } else {
        onSave({ ...values, imgUrl: '', isActive: values.isActive, isExclusive: values.isExclusive });
        onClose();
      }
    },
  });

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style} className="p-4">
        <form onSubmit={formik.handleSubmit}>
           {/* Image Upload and Preview */}
           <Box className="relative">
            <Card>
              {imagePreview ? (
                <CardMedia
                  component="img"
                  sx={{
                    width: 'fit-content',
                    height: 'auto',
                    maxHeight: 400,
                    display: 'block',
                    margin: 'auto',
                    objectFit: 'contain',
                  }}
                  image={imagePreview}
                  title="Uploaded Image"
                />
              ) : (
                <div className="flex justify-center items-center">
                  <img
                    src={'../assets/icons/DashboardAdmin/folder.png'}
                    alt="Fallback Icon"
                    style={{
                      width: 'auto',
                      height: 'auto',
                      maxHeight: '200px',
                      padding: '1em',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              )}
            </Card>
            <Box sx={{ position: 'absolute', bottom: 8, right: 8 }}>
              <input
                accept="image/*"
                type="file"
                onChange={handleImageChange}
                style={{ display: 'none' }}
                id="upload-image"
              />
              <label htmlFor="upload-image">
                <IconButton
                  component="span"
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    boxShadow: 2,
                    '&:hover': {
                      boxShadow: 4,
                    },
                    padding: 1,
                  }}
                >
                  <EditIcon />
                </IconButton>
              </label>
            </Box>
          </Box>

          {/* Title Input */}
          <TextField
            fullWidth
            margin="normal"
            id="title"
            name="title"
            label="Title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
          {/* Description Input */}
          <TextField
            fullWidth
            margin="normal"
            id="description"
            name="description"
            label="Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
          {/* Short Description Input */}
          <TextField
            fullWidth
            margin="normal"
            id="shortDesc"
            name="shortDesc"
            label="Short Description"
            value={formik.values.shortDesc}
            onChange={formik.handleChange}
          />
          {/* Date Pickers */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box className="flex justify-between items-center">
                <DatePicker
                  label="Start Date"
                  value={formik.values.startDay ? dayjs(formik.values.startDay) : null}
                  onChange={(newValue: Dayjs | null) => formik.setFieldValue('startDay', newValue?.toDate())}
                  sx={{ mb: 2 }}
                />
                <DatePicker
                  label="End Date"
                  value={formik.values.endDay ? dayjs(formik.values.endDay) : null}
                  onChange={(newValue: Dayjs | null) => formik.setFieldValue('endDay', newValue?.toDate())}
                  sx={{ mb: 2 }}
                />
              </Box>
          </LocalizationProvider>
            {/* Is Active Checkbox */}
          <FormControlLabel
            control=
            {
            <Switch
            onChange={formik.handleChange}
            checked={formik.values.isActive}
            color="success"
            name="isActive" 
          />
          }
            label="Active"
          />
          {/* Is Exclusive Checkbox */}
          <FormControlLabel
            control={  <Switch
              onChange={formik.handleChange}
              checked={formik.values.isExclusive}
              color="primary"
              name="isExclusive" 
            />}
            label="Exclusive"
          />
          {/* Buttons */}
          <Box className="flex flex-wrap gap-5 justify-center mt-4">
            <Button onClick={onClose} variant="contained" color="error">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Crear Promocion
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default CreatePromotionModal;