import React, { useState, useEffect } from 'react';
import {
  Card,
  CardMedia,
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  Skeleton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { getIconByName, PlanServiceInterface, socialMediaIcons } from '../../../../constant/ServicesInterfaces'; // Adjust import based on your structure
import useImageValidation from '../../../../utils/ImageValidator';
import { CheckCircle, Cancel } from '@mui/icons-material';
import { uploadImage } from '../../../../services/gcpService';
import { formatPrice } from '../../../../utils/ConvertApp';
import SpecificationsInput from '../Inputs/SpecificationsInput';
import PlanTypeInput from '../Inputs/PlanTypeInput';
import CheckboxTile from '../../Cards/CheckboxTile';

interface PlanDetailsModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (plan: PlanServiceInterface) => void;
  plan: PlanServiceInterface;
}

const PlanDetailsModal: React.FC<PlanDetailsModalProps> = ({
  open,
  onClose,
  onSave,
  plan,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [currentPlan,setCurrentPlan] = useState<PlanServiceInterface>(plan);
  const [updatedPlan, setUpdatedPlan] = useState<PlanServiceInterface>(plan);
  const { imgUrlIsValid, isLoading } = useImageValidation(updatedPlan.coverImgUrl || '');
  const [imageFile, setImageFile] = useState<File | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setUpdatedPlan((prevState) => ({ ...prevState, [name]: value }));
  };

    // Handle image upload
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setUpdatedPlan((prevState) => ({ ...prevState, coverImgUrl: reader.result as string }));
        };
        reader.readAsDataURL(file);
      }
    };
  

  const handleSave = async () => {
    try {
      let coverImgUrl = '';
      if (imageFile) {
        coverImgUrl = await uploadImage(imageFile, 'Plans');
      }

      const updatedPlanData = {
        ...updatedPlan,
        coverImgUrl: coverImgUrl || updatedPlan.coverImgUrl,
      };

      await onSave(updatedPlanData);
      setEditMode(false);
    } catch (error) {
      console.error('Failed to save the plan:', error);
    }
  };

  const handleSpecificationChange = (newSpecs: Record<string, any>) => {
    setUpdatedPlan((prevState) => ({
      ...prevState,
      specifications: newSpecs
    }));
  };

  const handlePlanTypeChange = (newPlanType: string) => {
    setUpdatedPlan({ ...updatedPlan, planType: newPlanType });
  };

  const handleCheckboxChange = (name: string, checked: boolean) => {
    setUpdatedPlan((prevPlan) => {
      const { socialMediaIncluded } = prevPlan;

      if (checked) {
        // Find the icon object based on the name
        const iconObject = socialMediaIcons.find((icon) => icon.name === name);
        
        if (iconObject) {
          // Add the item if it's checked
          return {
            ...prevPlan,
            socialMediaIncluded: [...socialMediaIncluded, {name}],
          };
        }
      } else {
        // Remove the item if it's unchecked
        return {
          ...prevPlan,
          socialMediaIncluded: socialMediaIncluded.filter(item => item.name !== name),
        };
      }
      return prevPlan; // Return the previous state if no changes were made
    });
  };

  
  const handleEditToggle = () => {
    setEditMode(true);
  };
  

  useEffect(() => {
    if (plan) {
      setUpdatedPlan(plan);
    }
  }, [plan]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Card>
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                width="60%"
                height={200}
                animation="wave"
                sx={{ borderRadius: '4px' }}
              />
            ) : imgUrlIsValid ? (
              <CardMedia
                component="img"
                sx={{
                  width: 'fit-content%',
                  height: 'auto',
                  maxHeight: 400,
                  display: 'block',
                  margin: 'auto',
                  objectFit: 'fill',
                }}
                image={updatedPlan.coverImgUrl}
                title={updatedPlan.planName}
              />
            ) : (
              <div className="flex justify-center items-center">
                <img
                  src={'../assets/icons/DashboardAdmin/folder.png'}
                  alt="Fallback Icon"
                  style={{
                    width: 'auto',
                    height: 'auto',
                    maxHeight: '200px',
                    padding: '1em',
                    objectFit: 'contain',
                  }}
                />
              </div>
            )}
          </Card>

          {editMode && (
            <Box className="relative">
              <Box sx={{ position: 'absolute', bottom: 8, right: 8 }}>
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  id="upload-company-logo"
                />
                <label htmlFor="upload-company-logo">
                  <IconButton
                    component="span"
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      boxShadow: 2,
                      '&:hover': { boxShadow: 4 },
                      padding: 1,
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </label>
              </Box>
            </Box>
          )}


          <Box sx={{ mt: 2 }}>
            {editMode ? (
               <Box  className="flex flex-wrap gap-5">
                <Box className="flex flex-wrap justify-between gap-5 w-full">
                  <TextField
                    label="Nombre del Plan"
                    name="planName"
                    value={updatedPlan.planName}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                  />
                  <Box  className="grow flex justify-end">
                    <PlanTypeInput planType={updatedPlan.planType} onChange={handlePlanTypeChange} />
                  </Box>
                </Box>
                <Box  className="flex flex-wrap gap-5">
                  <TextField
                    label="Datos Incluidos (GB)"
                    name="dataIncludedGB"
                    type="number"
                    value={updatedPlan.dataIncludedGB}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Minutos Incluidos"
                    name="minutesIncluded"
                    type="number"
                    value={updatedPlan.minutesIncluded}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="SMS Incluidos"
                    name="smsIncluded"
                    type="number"
                    value={updatedPlan.smsIncluded}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Costo Adicional por KB"
                    name="additionalCostPerKB"
                    type="number"
                    value={updatedPlan.additionalCostPerKB}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                  />
                </Box>
                <TextField
                  label="Precio"
                  name="price"
                  type="number"
                  value={updatedPlan.price}
                  onChange={handleInputChange}
                  sx={{ mb: 2 }}
                />
                <Typography variant="body1" width={'100%'}>
                  <strong>Redes Sociales Incluidas:</strong>
                </Typography>
                <Box className="flex flex-wrap gap-5 justify-center my-5">
                {socialMediaIcons &&
                  socialMediaIcons.map((option, index) => {
                    return (
                      <CheckboxTile
                        key={index}
                        label={option.name}
                        iconSrc={option.icon}
                        isChecked={
                          updatedPlan.socialMediaIncluded?.some(item => item.name === option.name) || false
                        }
                        onChange={(checked) => handleCheckboxChange(option.name, checked)}
                      />
                    );
                  })}
              </Box>
                <SpecificationsInput
                  specifications={updatedPlan.specifications || {}}
                  onChange={handleSpecificationChange}
                />
              </Box>
            ) : (
              <Box className="flex flex-wrap gap-5 p-4 bg-gray-100 rounded-lg shadow-md">
                <Typography variant="body1">
                  <strong>Nombre del Plan:</strong> {updatedPlan.planName}
                </Typography>
                <Typography variant="body1">
                  <strong>Tipo de plan:</strong> {updatedPlan.planType}
                </Typography>
                <div className="flex items-center">
                  {updatedPlan.isActive ? (
                    <CheckCircle className="text-green-500 mr-2" />
                  ) : (
                    <Cancel className="text-red-500 mr-2" />
                  )}
                  <Typography variant="body1">
                    <strong>Plan Activo:</strong> {updatedPlan.isActive ? 'Sí' : 'No'}
                  </Typography>
                </div>
                <Box  className="flex flex-wrap gap-5">
                  <Typography variant="body1">
                    <strong>Datos Incluidos (GB):</strong> {updatedPlan.dataIncludedGB}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Minutos Incluidos:</strong> {updatedPlan.minutesIncluded}
                  </Typography>
                  <Typography variant="body1">
                    <strong>SMS Incluidos:</strong> {updatedPlan.smsIncluded}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Costo Adicional por KB:</strong> {updatedPlan.additionalCostPerKB}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Precio:</strong> ${formatPrice(updatedPlan.price)}
                  </Typography>
                  <Box  className="flex flex-wrap gap-2">
                  <Typography variant="body1">
                    <strong>Redes Sociales Incluidas:</strong>
                  </Typography>
                  {Array.isArray(updatedPlan.socialMediaIncluded) && 
                      updatedPlan.socialMediaIncluded.map((item, index) => (
                        <div key={index}>
                          {getIconByName(item.name)} {/* You can render social media details here, for example */}
                        </div>
                      ))
                    }
                </Box>
              </Box>
              <Box>
                <Typography variant="body1">
                  <strong>Especificaciones:</strong>
                </Typography>
                {updatedPlan.specifications ? (
                  <Box component="ul" sx={{ pl: 2, display:'flex', flexWrap:'wrap' , gap:'1rem' }}>
                    {Object.entries(updatedPlan.specifications).map(([key, value], index) => (
                      <Box component="li" key={index}>
                        <Typography variant="body2">
                          <strong>{key}:</strong> {String(value)}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Typography variant="body1">N/A</Typography>
                )}
              </Box>
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button onClick={onClose} color="error" variant="contained">
              Cerrar
            </Button>
            {editMode ? (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button onClick={() => {setEditMode(false); setUpdatedPlan(currentPlan)}} color="error" variant="outlined">
                  Cancelar
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                  Guardar
                </Button>
              
              </Box>
            ) : (
              <Button onClick={handleEditToggle} color="primary" variant="contained">
                Editar
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PlanDetailsModal;