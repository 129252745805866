import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Typography,
  Card,
  CardMedia,
  Box,
  IconButton,
  Skeleton,
  Alert,
  CardContent,
  Grid,
  Switch,
  Rating,
  InputAdornment,
  Paper,
} from '@mui/material';
import { Cancel, CheckCircle, CloudUpload as CloudUploadIcon, Edit as EditIcon } from '@mui/icons-material';
import { SmartphoneDetailsInterface, SmartphoneInterface, SmartphonePriceInterface } from '../../../../constant/PhoneModel';
import useImageValidation from '../../../../utils/ImageValidator';
import { getSmarthphoneDeatilById, getSmarthphonePriceById } from '../../../../services/products';
import SmartphoneDetailsBox from './SmartphoneDetailsBox';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

interface EditSmartphoneModalProps {
  open: boolean;
  onClose: () => void;
  smartphone: SmartphoneInterface;
  onSave: (
    smartphone: SmartphoneInterface, 
    smartphoneDetails:SmartphoneDetailsInterface,
    smartphonePrices:SmartphonePriceInterface[] ) => void;
}

const defaultSmartphoneDetails: SmartphoneDetailsInterface = {
  id: 0, // Use a default ID or generate one
  smartphoneId: '', // Default value, adjust as necessary
  price: 0, // Default price
  capacity: 0, // Default capacity
  screenSize: 0, // Default screen size
  opSystem: '', // Default operating system
  camera: '', // Default camera information
  color: '', // Default color
  memoryRam: '', // Default RAM specification
  description: '', // Default description
  productImages: [], // Default to an empty array
  rating: 0, // Default rating
  createdAt: new Date(), // Default to current date
  updatedAt: new Date(), // Default to current date
  connectivity: null, // Default connectivity
  features: {}, // Default to an empty object for features
};

const initialPrices: SmartphonePriceInterface[] = [
  { id: 1, capacity: 64, color: 'Black', contractMode: 'AMIGO KIT', price: 100, smartphoneId: '1' }
];

const EditSmartphoneModal: React.FC<EditSmartphoneModalProps> = ({
  open,
  onClose,
  smartphone,
  onSave
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [updatedProduct, setUpdatedProduct] = useState<SmartphoneInterface>(smartphone);
  const [updatedSmartphoneDetails, setUpdatedSmartphoneDetails] = useState<SmartphoneDetailsInterface>(defaultSmartphoneDetails);
  const [updatedSmartphonePrices, setUpdatedSmartphonePrices] = useState<SmartphonePriceInterface[]>();
  const [priceAmigo, setPriceAmigo] = useState<number>(0);
  const [priceTarifado, setPriceTarifado] = useState<number>(0);
  const [isPriceEnabled, setIsPriceEnabled] = useState<boolean>(false);
  const [isContractPriceEnabled, setIsContractPriceEnabled] = useState<boolean>(false);
  const [productName, setProductName] = useState<string>(smartphone.productName);
  const [brand, setBrand] = useState<string>(smartphone.brand);
  const [price, setPriceId] = useState<number>(smartphone.price);
  const [shortDesc, setShortDesc] = useState<string>(smartphone.shortDesc || '');
  const [inStock, setInStock] = useState<boolean>(smartphone.inStock || false);
  const [rating, setRating] = useState<number>(smartphone.rating || 0);
  const [loading, setLoading] = useState<boolean>(false);
  const { imgUrlIsValid, isLoading } = useImageValidation(updatedProduct.imgUrl);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // Estado para manejar el segundo diálogo
  const [imageFile, setImageFile] = useState<File | null>(null); // State for the uploaded image file
  const imageFiles = useSelector((state: RootState) => state.images.imageFiles);


  useEffect(()=>{
    handleFetchSmarhphoneDetails(smartphone.id);
  },[]);

  const handleFetchSmarhphoneDetails = async (smartphoneId: string): Promise<void> => {
    try {
      setLoading(true);
      const details = await getSmarthphoneDeatilById(smartphoneId);
      const pricesDetails = await getSmarthphonePriceById(smartphoneId);
      setUpdatedSmartphoneDetails(details);
      setUpdatedSmartphonePrices(pricesDetails);

    } catch (error) {
      console.error('Error fetching smartphones:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      let imgUrl =' ';

      if (imageFile) {
        //imgUrl = await uploadImage(imageFile, 'Smartphones'); // Upload image to your storage
      }

      const updatePrices = handlePriceChange(initialPrices);
      //const updateSmartphoneDetailsImages = 1
      //handleSmartDetailsImages();

      onSave({
        ...smartphone,
        productName,
        brand,
        price: updatePrices[0].price,
        shortDesc,
        inStock,
        rating,
        imgUrl: imageFile ? imgUrl:updatedProduct.imgUrl,
      },updatedSmartphoneDetails,
      updatePrices);
      onClose();
    } catch (error) {
      console.error('Error saving smartphone:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDetailsChange = (updatedDetails: SmartphoneDetailsInterface) => {
    setUpdatedSmartphoneDetails(updatedDetails);
  };

  const handleSmartDetailsImages = () =>{
    let newProductsImages = [];
    console.log(imageFiles);
  }

  const handlePriceChange = (newPrices: SmartphonePriceInterface[]) => {
    let updatedPricesArray =[...newPrices]
    if(updatedSmartphonePrices){
       updatedPricesArray = [...updatedSmartphonePrices];
    }
    // Flags to track if 'AMIGO KIT' and 'TARIFADO' exist
    let amigoKitExists = false;
    let tarifadoExists = false;

    updatedPricesArray.forEach((item) => {
      if (item.contractMode === 'AMIGO KIT') {
        item.price=priceAmigo;
        setIsPriceEnabled(true);
        amigoKitExists = true; // Mark 'AMIGO KIT' as found
      } else if (item.contractMode === 'TARIFADO') {
        item.price = priceTarifado;
        setIsContractPriceEnabled(true);
        tarifadoExists = true; // Mark 'TARIFADO' as found
      }
    });

    // If 'AMIGO KIT' doesn't exist, add it with the specified price
    if (!amigoKitExists && isPriceEnabled) {
      updatedPricesArray.push({ 
        id:0,
        contractMode: 'AMIGO KIT', 
        price: priceAmigo,
        color:updatedSmartphoneDetails.color,
        smartphoneId:updatedProduct?.id,
        capacity:updatedProduct.capacity
       });
    }

    // If 'TARIFADO' doesn't exist, add it with the specified price
    if (!tarifadoExists && isContractPriceEnabled) {
      updatedPricesArray.push({
        id:0, 
        contractMode: 'TARIFADO', 
        price: priceTarifado,  
        color:updatedSmartphoneDetails.color,
        smartphoneId:updatedProduct.id,
        capacity:updatedProduct.capacity
      });
    }

    // Update the state with the new array of prices
    setUpdatedSmartphonePrices(updatedPricesArray);
    return updatedPricesArray;
    
  };

  const handlePriceEdit = () => {
    updatedSmartphonePrices && updatedSmartphonePrices.map((item, index) => {
        if(item.contractMode=='AMIGO KIT'){
          setPriceAmigo(item.price);
          setIsPriceEnabled(true);
        }else{
          setPriceTarifado(item.price);
          setIsContractPriceEnabled(true);
        }
    })
  };

  const handlePriceSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPriceEnabled(event.target.checked);
    // If disabling, set the value to 0
  };
  
  const handleContractPriceSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsContractPriceEnabled(event.target.checked);
    // If disabling, set the value to 0
  };

  const handleUpdateConfirmation = () => {
    setConfirmDialogOpen(true); // Abrir el segundo diálogo
  };

   // Handle image upload
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setUpdatedProduct((prevState) => ({ ...prevState, imgUrl: reader.result as string }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditToggle = () => {
    handlePriceEdit();
    setIsEditing(true);
  };



  return (
    <>
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{isEditing ? 'Editar Smartphone' : 'Detalles del Smartphone'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSave} className="space-y-4">
        <Card>
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              width="60%"
              height={200}
              animation="wave"
              sx={{ borderRadius: '4px' }}
            />
          ) : imgUrlIsValid ? (
            <CardMedia
              component="img"
              sx={{
                width: 'auto', // Set width to 70%
                height: 250, // Allow height to adjust automatically
                display: 'block',
                margin: 'auto',
                objectFit: 'cover',
              }}
              image={updatedProduct.imgUrl}
              title={updatedProduct.productName}
          />
          ) : (
            <div className="flex justify-center items-center">
              <img
                src={'../assets/icons/DashboardAdmin/folder.png'}
                alt="Fallback Icon"
                style={{
                  width: 'auto',
                  height: 'auto',
                  maxHeight: '200px',
                  padding: '1em',
                  objectFit: 'contain',
                }}
              />
            </div>
          )}
        </Card>
          {/* Image Upload Input */}
          {isEditing && (
          <Box className='relative'>
            <Box sx={{ position: 'absolute', bottom: 20, right: 8 }}> {/* Positioning for the edit icon */}
            <input
              accept="image/*"
              type="file"
              onChange={handleImageChange}
              style={{ display: 'none' }}
              id="upload-image"
            />
            <label htmlFor="upload-image">
            <IconButton
              component="span"
              sx={{
                backgroundColor: 'white', // Set background color to white
                borderRadius: '50%', // Make it round
                boxShadow: 2, // Add shadow for elevation
                '&:hover': {
                  boxShadow: 4, // Increase shadow on hover for effect
                },
                padding: 1, // Add padding to make it look better
              }}
            >
                <EditIcon />
              </IconButton>
            </label>
          </Box>
         </Box>
        )}
        
          {isEditing ? (
            <Box className='flex flex-wrap gap-5 justify-between'>
              <TextField
                label="Nombre del Producto"
                variant="outlined"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
              />

              <TextField
                label="Marca"
                variant="outlined"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                required
              />
              
            <Box className='flex flex-wrap justify-around w-full'>

                <FormControlLabel
                  control={
                    <Switch
                        checked={inStock}
                        onChange={(e) => setInStock(e.target.checked)}
                        color="primary" // Customize the color of the switch
                      />
                  }
                  label="¿En Stock?"
                />

              <Box className="flex items-center">
                  <Rating
                    name="rating"
                    value={rating}
                    onChange={(event, newValue) => {
                      setRating(newValue || 0); // Ensure it handles null values
                    }}
                    precision={0.5} // Allow half-star ratings
                  />
                  <span className="ml-2">{rating}</span>
                </Box>
              </Box>
              <TextField
                label="Descripción Corta"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                value={shortDesc}
                onChange={(e) => setShortDesc(e.target.value)}
              />

            <Box sx={{ padding: 2 }} className="flex flex-wrap gap-5">
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">Amigo Kit</Typography>
                    <TextField
                      label="Precio"
                      type="number"
                      variant="outlined"
                      value={priceAmigo}
                      sx={{ marginBottom: 2, width: '100%' }} // Full width for better usability
                      disabled={!isPriceEnabled}
                      onChange={(e) => setPriceAmigo(Number(e.target.value))}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isPriceEnabled}
                          onChange={handlePriceSwitchChange}
                          color="primary"
                        />
                      }
                      label="Habilitar Precio"
                      sx={{ marginLeft: 1 }} // Use theme spacing for consistency
                    />
                  </Box>
                  
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">Tarifado</Typography>
                    <TextField
                      label="Precio"
                      type="number"
                      variant="outlined"
                      value={priceTarifado}
                      sx={{ marginBottom: 2, width: '100%' }} // Full width for better usability
                      disabled={!isContractPriceEnabled}
                      onChange={(e) => setPriceTarifado(Number(e.target.value))}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isContractPriceEnabled}
                          onChange={handleContractPriceSwitchChange}
                          color="primary"
                        />
                      }
                      label="Habilitar Precio"
                      sx={{ marginLeft: 1 }} // Use theme spacing for consistency
                    />
                  </Box>
                </Box>
            </Box>
          ) : (
            <Box className="flex flex-wrap gap-5 p-4 bg-gray-100 rounded-lg shadow-md">
              <Typography variant="body1"><strong>{productName}</strong> </Typography>
              <Typography variant="body1"><strong>Marca:</strong> {brand}</Typography>
              <Typography variant="body1"><strong>Precio:</strong> ${price}</Typography>
              <Typography variant="body1"><strong>Descripción:</strong> {shortDesc}</Typography>
              <Typography variant="body1"><strong>En Stock:</strong>  {inStock ? (
                  <CheckCircle className="text-green-500 mr-2" />
                ) : (
                  <Cancel className="text-red-500 mr-2" />
                )}</Typography>
              <Typography variant="body1"><strong>Calificación:</strong> {rating}</Typography>
              <Box className='p-2 flex flex-wrap gap-5 w-full '>
              {updatedSmartphonePrices && updatedSmartphonePrices.map((item, index) => (
                <Paper key={index} elevation={1} sx={{ padding: 2, marginBottom: 2, borderRadius: 1 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      Tipo de Plan:
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      {item.contractMode}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      Precio:
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                      ${item.price.toFixed(2)} {/* Ensures two decimal places */}
                    </Typography>
                  </Box>
                </Paper>
              ))}
              </Box>
            </Box>
          )}
           {loading ? (
              <Card className="w-full shadow-lg mt-4">
                <CardContent>
                  <Grid container spacing={2}>
                    {/* You can repeat these Skeletons for each field in the component */}
                    <Grid item xs={6}>
                      <Skeleton variant="rectangular" height={50} />
                    </Grid>
                    <Grid item xs={6}>
                      <Skeleton variant="rectangular" height={50} />
                    </Grid>
                    <Grid item xs={6}>
                      <Skeleton variant="rectangular" height={50} />
                    </Grid>
                    <Grid item xs={6}>
                      <Skeleton variant="rectangular" height={50} />
                    </Grid>
                    {/* Repeat more Skeletons as needed */}
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              updatedSmartphoneDetails && <SmartphoneDetailsBox
                details={updatedSmartphoneDetails} // Non-null assertion since details will be available after loading
                isEditMode={isEditing}
                productName={updatedProduct.productName}
                onDetailsChange={handleDetailsChange}
              />
            )}
        </form>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button onClick={onClose} color="error" variant="contained">
            Cerrar
          </Button>
        {isEditing ? (
            <Box className='flex flex-wrap gap-5'>
            <Button onClick={()=> setIsEditing(false)} color="error" variant="contained">
              Cancelar
            </Button>
            <Button onClick={handleUpdateConfirmation} color="primary" variant="contained">
              Guardar
            </Button>
            </Box>
          ) : (
            <Button onClick={handleEditToggle} color="primary" variant="contained">
              Editar
            </Button>
          )}
      </DialogActions>
    </Dialog>
     <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
     <DialogTitle className="text-xl font-bold">Confirmar Actualización</DialogTitle>
     <DialogContent>
       <Alert severity="warning">Esta acción no se puede deshacer.</Alert>
       <Typography className='mt-5'>
         ¿Estás absolutamente seguro de que deseas actualizar <b>{smartphone.productName}</b>?
       </Typography>
     </DialogContent>
     <DialogActions>
       <Button onClick={() => setConfirmDialogOpen(false)}>Cancelar</Button>
       <Button onClick={handleSave} color="success" variant="contained">
         Confirmar
       </Button>
     </DialogActions>
   </Dialog>
   </>
  );
};

export default EditSmartphoneModal;