import React, { useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Button, Box, Rating, Chip, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Stack, Modal, Paper, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { Smartphone, SmartphoneDetailsInterface, SmartphoneInterface, SmartphonePriceInterface, UpdatePriceModel } from '../../../constant/PhoneModel';
import { createMultipleSmarthphones, createMultipleSmartphonesDetails, deleteLatestProduct, deleteLatestProductDetails, getAllSmarthphones, updateMultipleSmartphoneDetailsPrice, updateMultipleSmartphonesPrice } from '../../../services/products';
import { createMultipleSmartphonePrices, deleteLatestPrice, updateMultiplePricesBySmarthphoneId } from '../../../services/smartphonePriceService';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import DeleteProductModal from '../Modal/Products/DeleteProducModal';
import { ArrowUpward, ArrowDownward, CheckCircle} from '@mui/icons-material';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import { formatPrice } from '../../../utils/ConvertApp';
import { useSnackbar } from '../../Alerts/SnackBarProvider';
import { mapDataToInterfaces, transformDataToSmartphones, validateHeaders } from './Utils/Mapper';

const defaultImgUrl = 'https://storage.googleapis.com/4-evolucion-cloud-storage/Smartphones/telefono-inteligente.png'; // Example placeholder
interface ExcelReaderProps {
  existingPhones: SmartphoneInterface[];
}

const ExcelReader: React.FC<ExcelReaderProps> = ({existingPhones}) => {
  const [data, setData] = useState<any[]>([]);
  const [smartphones, setSmartphones] = useState<SmartphoneInterface[]>([]); // State for smartphones
  const [duplicateSmartphones, setDuplicateSmartphones] = useState<any[]>([]); // State for smartphones
  const [updateExistingPhone,setUpdateExistingPhones] = useState<SmartphoneInterface[]>(existingPhones);
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [saveSuccessfully, setSaveSuccessfully] = useState(false);
  const [updatesSuccessfully, setUpdatesSuccessfully] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSmartphone, setSelectedSmartphone] = useState<SmartphoneInterface | null>(null); // Smartphone to be deleted
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const fetchSmartphones = async () => {
    try {
      setLoading(true);
      const data = await getAllSmarthphones();
      setUpdateExistingPhones(data);
    } catch (error) {
      console.error('Error fetching smartphones:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSaveSuccessfully(false);
    setUpdatesSuccessfully(false);
    fetchSmartphones();
    const file = event.target.files?.[0];
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryStr = e.target?.result as string;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        // Validate that the file contains at least one sheet
        if (!workbook.SheetNames.length) {
          showSnackbar('El número de columnas en el archivo son incorrectas ', 'error');
          handleClearInput();
          return;
        }
        // Read the first sheet and convert it to JSON
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        // Ensure the file has rows
        if (!jsonData.length || !Array.isArray(jsonData[0])) {
          showSnackbar('The Excel file headers do not match the expected format.', 'error');
          handleClearInput();
          return;
        }
        // Extract the headers (first row)
        const headers = jsonData[0]; // This is the header row
        console.log('List of Headers:', headers);

        // Validate headers
        const isValidHeader = validateHeaders(headers);
        if (!isValidHeader) {
          showSnackbar('El nombre de las columnas en el archivos son incorrectas', 'error');
          handleClearInput();
          return;
        }

        const dataWithoutHeader = jsonData.slice(1); // This skips the first row 
        const list: any[] = dataWithoutHeader.map((row) => transformDataToSmartphones(row));

        const flattenedList = list.reduce((acc, innerArray) => {
          return [...acc, ...innerArray];
        }, []);

       const uniqueList = Array.from(
        new Map(flattenedList.map((item:any) => [item.id, item])).values()
      );

       handleExistingPhone(uniqueList);
       console.log(data);
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };

      reader.readAsBinaryString(file);
    }
  };

  const handleExistingPhone = (phonesData:any[]) => {
  
    // Check if updateExistingPhone is valid
    if (!updateExistingPhone || !Array.isArray(updateExistingPhone)) {
      console.error('updateExistingPhone is not an array or is undefined.');
      return;
    }

    const existingPhoneIds = updateExistingPhone.map((phone: SmartphoneInterface) => phone.id);  
    const uniqueSmartphones: any[] = [];
    const duplicateSmartphones: any[] = [];
  
    // Iterate over data and check if each smartphone exists in updateExistingPhone
    phonesData.forEach((smartphone: any) => {
      const exists = existingPhoneIds.includes(smartphone.id.toString());

      if (exists) {
        duplicateSmartphones.push(smartphone);  // If the phone exists, push to duplicateSmartphones
      } else {
        uniqueSmartphones.push(smartphone);  // If the phone doesn't exist, push to uniqueSmartphones
      }
    });

    const updatedPhones = updateExistingPhone.map((phone) => {
      // Find the matching duplicate phone with the same id
      const duplicate = duplicateSmartphones.find((dupPhone) =>
        dupPhone.id.toString() === phone.id.toString()
      );
    
      // If a matching phone exists and the prices are different, update the price
      if (duplicate && Number(phone.price) !== duplicate.price) {
        console.log(`Updating phone ID ${phone.id} from price ${phone.price} to ${duplicate.price}`);
        
        // Return the updated phone along with the old price and new price
        return { 
          ...phone, 
          oldPrice: phone.price,  // Store the original price
          newPrice: duplicate.price  // Store the updated price
        };
      }
    
      // If no update is needed, return null (indicating no change)
      return null;
    }).filter((updatedPhone) => updatedPhone !== null);  // Filter out the phones that haven't changed

    
    setData(uniqueSmartphones);
    setSmartphones(uniqueSmartphones);
    setDuplicateSmartphones(updatedPhones);
  };

  const handleClearInput = () => {
    // Clear the input field by accessing the ref and setting its value to ''
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the file input
    }
  };

  const handleMappingToPhone = async () => {
    setLoading(true); // Start loading
    try {
      const { smartphonePrices, smartphones, smartphoneDetails } = mapDataToInterfaces(data);
      // Concurrent requests
     await Promise.all([
        createMultipleSmarthphones(smartphones),
        createMultipleSmartphonesDetails(smartphoneDetails),
        createMultipleSmartphonePrices(smartphonePrices)
      ]);

      setSaveSuccessfully(true);
      setOpenDialog(true); // Open success dialog on successful creation
    } catch (error) {
      console.error("Error while mapping and creating smartphones:", error);
      await deleteLatest();
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const deleteLatest = async () => {
    try {
      // Delete the latest smartphone, smartphone details, and smartphone prices
      await Promise.all([
        deleteLatestProduct(),
        deleteLatestProductDetails(),
        deleteLatestPrice()
      ]);
      console.log("Successfully deleted the latest entries.");
    } catch (deleteError) {
      console.error("Error deleting the latest entries:", deleteError);
    }
  };


  const handleUpdatePhones = async () =>{
    setLoadingUpdate(true);
    try {
      if(duplicateSmartphones.length <= 0){
        return;
      }

      const newPhonePrices: UpdatePriceModel[] = duplicateSmartphones.map(item => ({
        smartphoneId: item.id,
        newPrice: item.newPrice
      }));

      console.log("newPrices",newPhonePrices);

      await Promise.all([
        updateMultipleSmartphonesPrice(newPhonePrices),
        updateMultipleSmartphoneDetailsPrice(newPhonePrices),
        updateMultiplePricesBySmarthphoneId(newPhonePrices)
      ]);

      setUpdatesSuccessfully(true);
      setOpenDialog(true); // Open success dialog on successful creation
       
    } catch (error) {
      console.error("Error while mapping and creating smartphones:", error)
    }finally {
      setLoadingUpdate(false); // Stop loading
    }

  }


  // Function to handle closing the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };



  const handleRemoveFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the input value
    }
    clearData();
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const updatedSmartphones = data.filter(
        (smartphone:any) => smartphone.id !== id
      );
      setIsDeleteModalOpen(false);
      setData(updatedSmartphones);
    } catch (error) {
      console.error('Error deleting promotion:', error);
      //showSnackbar('Error deleting promotion', 'error');
    }
  };

  const handleModalClose = () => {
    setIsDeleteModalOpen(false);
    setSelectedSmartphone(null);
  };


  // Clears the uploaded data
  const clearData = () => {
    setData([]);
    setSmartphones([]); // Clear smartphones data as well
    setDuplicateSmartphones([]);
  };

  const columns: GridColDef[] = [
    { 
      field: 'id', 
      headerName: 'SAP ID', 
      width: 150 
    },
    { 
      field: 'modalidad', 
      headerName: 'Modalidad', 
      width: 150 
    },
    { 
      field: 'productName', // Updated to match the interface
      headerName: 'Telefono', 
      width: 250, 
      renderCell: (params) => (
        <Typography variant="body2" fontWeight="bold" className='flex items-center h-full'>
          {params.value}
        </Typography>
      )
    },
    { 
      field: 'brand', 
      headerName: 'Brand', 
      width: 150 
    },
    { 
      field: 'color', 
      headerName: 'Color', 
      width: 150 
    },
    { 
      field: 'capacity', 
      headerName: 'Capacity (GB)', 
      width: 130 
    },
    { 
      field: 'memoryRam', // Updated to match the interface
      headerName: 'RAM', 
      width: 100 
    },
    { 
      field: 'camera', 
      headerName: 'Camera', 
      width: 130 
    },
    { 
      field: 'screenSize', // Updated to match the interface
      headerName: 'Screen Size', 
      width: 120 
    },
    { 
      field: 'opSystem', // Updated to match the interface
      headerName: 'Android Version', 
      width: 130 
    },
    { 
      field: 'price', 
      headerName: 'Price (MXN)', 
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" height={50}>
          <Typography variant="body2">
            {formatPrice(params.row.price)}
          </Typography>
        </Stack>
      ), 
    },
    { 
      field: 'rating', 
      headerName: 'Rating', 
      width: 120, 
      renderCell: (params) => (
        <Rating value={params.value || 0} precision={0.5} readOnly />
      )
    },
    { 
      field: 'productImages', // Assuming this is an array of images, displaying the first image
      headerName: 'Image', 
      width: 150, 
      renderCell: (params) => (
        <img src={params.value[0] || defaultImgUrl} alt={params.row.description} width={50} height={50} />
      )
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1}>
           <IconButton
            onClick={() => {
              handleDelete(params.row.id);
            }}
      >
            <DeleteIcon className='text-red-500'/>
          </IconButton>
        </Stack>
      ),
    },
  ];

  const columnsModifyPrice: GridColDef[] = [
    { 
      field: 'id', 
      headerName: 'SAP ID', 
      width: 150 
    },
    { 
      field: 'productName', // Updated to match the interface
      headerName: 'Telefono', 
      width: 250, 
      renderCell: (params) => (
        <Typography variant="body2" fontWeight="bold">
          {params.value}
        </Typography>
      )
    },
    {
      field: 'price',
      headerName: 'Cambio de Precio (MXN)',
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        const oldPrice = Number(params.row.price);
        const newPrice = Number(params.row.newPrice);
  
        // Determine if price is increasing or decreasing
        const isPriceIncreased = newPrice > oldPrice;
        const isPriceDecreased = newPrice < oldPrice;
  
        return (
          <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" height={50}>
            {/* Old Price */}
            <Typography variant="body2" sx={{ color: isPriceIncreased || isPriceDecreased ? 'inherit' : 'gray' }}>
              {formatPrice(oldPrice)}
            </Typography>
            {/* Arrow for Price Change */}
            {isPriceIncreased && <ArrowUpward sx={{ color: 'green', fontSize: 20 }} />}
            {isPriceDecreased && <ArrowDownward sx={{ color: 'red', fontSize: 20 }} />}
            {/* New Price */}
            <Typography variant="body2" sx={{ color: isPriceIncreased || isPriceDecreased ? 'inherit' : 'gray', fontWeight:'bold' }}>
              {formatPrice(newPrice)}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1}>
           <IconButton
            onClick={() => {
              setSelectedSmartphone(params.row as SmartphoneInterface); // Set the smartphone to be deleted
              setIsDeleteModalOpen(true); // Open confirmation modal
            }}
      >
            <DeleteIcon className='text-red-500'/>
          </IconButton>
        </Stack>
      ),
    },
  ];



  return (
    <Box p={4}>
      <h1 className="text-xl font-bold">Lector de Archivos</h1>
      <Box display="flex" flexDirection="column" gap={2} alignItems="start">
      {/* File Input (Hidden) */}
      <input
        ref={fileInputRef}
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        style={{ display: "none" }}
      />

      {/* Upload Button */}
      <Button
        variant="contained"
        startIcon={<UploadFileIcon />}
        onClick={triggerFileInput}
      >
        {selectedFile ? "Cambiar Archivo" : "Seleccionar Archivo"}
      </Button>

      {/* File Details */}
      {selectedFile && (
        <Paper
          elevation={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: 1,
            borderRadius: 1,
            mt: 1,
          }}
        >
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
               Archivo seleccionado:
            </Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              {selectedFile.name}
            </Typography>
          </Box>
          <IconButton
            size="small"
            color="error"
            onClick={handleRemoveFile}
            aria-label="Remove file"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Paper>
      )}
      </Box>

      <Box sx={{ display: "flex", gap: 4, mt: 4, flexWrap: "wrap" }}>

      {data.length <= 0 && duplicateSmartphones.length <= 0 && selectedFile && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <InfoIcon sx={{ color: '#0061AD', marginRight: 1 }} />
            <Typography variant="h6">Elementos ya existen</Typography>
          </Box>
          <DialogContent>
            <Typography variant="body1">
              Todos los elementos en el archivo ya existen en la base de datos.
            </Typography>
          </DialogContent>
        </Box>
      )}
      {/* Nuevos Smartphones */}
      {data.length > 0 && (
        <Paper elevation={3} sx={{ flex: 1, minWidth: 300, p: 2 }}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Nuevos Smartphones
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
          {saveSuccessfully ? <>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircle sx={{ color: 'green', marginRight: 1 }} />
              <Typography variant="h6">Operación Exitosa</Typography>
            </Box>
          <DialogContent>
            <Typography variant="body1">
              Los Telefonos se han guardado correctamente.
            </Typography>
          </DialogContent>
          </Box>          
          </>:<>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid rows={data} columns={columns} />
            </Box>
            <Button  sx={{ marginTop: 2 }} 
            variant="contained" 
            color="primary" 
            onClick={handleMappingToPhone} 
            disabled={loading || data.length <0}
            >
              {loading ? <CircularProgress size={24} /> : 'Guardar'}
            </Button>
            
          </>
          }
        </Paper>
      )}

      {/* Smartphones Duplicados */}
      {duplicateSmartphones.length > 0 && (
       <Paper elevation={3} sx={{ flex: 1, minWidth: 300, p: 2 }}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Smartphones Duplicados - Nuevo Precio
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
          {updatesSuccessfully ? <>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircle sx={{ color: 'green', marginRight: 1 }} />
              <Typography variant="h6">Actualización Exitosa</Typography>
            </Box>
          <DialogContent>
            <Typography variant="body1">
              Los precios se han actualizado correctamente.
            </Typography>
          </DialogContent>
          </Box>          
          </>:<>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid rows={duplicateSmartphones} columns={columnsModifyPrice} />
            </Box>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 2 }}
              onClick={handleUpdatePhones} 
            >
              {loadingUpdate ? <CircularProgress size={24} /> : 'Actualizar Precios'}
            </Button>
          </>}

        </Paper>
      )}
    </Box>
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>Operación Exitosa</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Los teléfonos  se han guardado correctamente.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
    {selectedSmartphone && isDeleteModalOpen && (
      <DeleteProductModal
        open={isDeleteModalOpen}
        onClose={handleModalClose}
        onDelete={() => handleDelete(selectedSmartphone?.id)}
        product={selectedSmartphone}
      />
    )}
  </Box>
  );
};

export default ExcelReader;